@import '~antd/dist/antd.less';

@font-face {
  font-family: 'Poppins';
  font-weight: normal;
  font-style: normal;
  src: url('assets/fonts/Poppins-Regular.ttf') format('truetype');
}

:root {
  font-size: 10px;/* Standard syntax */
}

*,
*::after,
*::before {
  font-family: 'Poppins';
  font-weight: normal;
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  background-color: #ffffff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@primary-color: #459FEA;@primary-1: #EAEEF8;@primary-2: #B7C9F5;@breadcrumb-icon-font-size: 20px;@card-padding-base: 20px;@btn-default-color: @primary-color;@info-color: @blue-6;@layout-trigger-background: #fff;@layout-trigger-color: #262626;